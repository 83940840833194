export default {
  "signin": 'Sign in',
  "start_for_free": "Start for free",
  "features": "Features",
  "pricing": "Pricing",
  "landing_home_section1_top_line": 'Take your business to the next level',
  "landing_home_section1_head_line": 'Increase the number of bookings with us',
  "landing_home_section1_description": 'Let new customers easily find you on the Internet and book a service in your sports or entertainment facility in seconds.',
  "landing_home_section1_button_label": 'Start for free',
  "landing_home_section2_top_line": 'EASY SETUP',
  "landing_home_section2_head_line": 'Super fast and simple onboarding process',
  "landing_home_section2_description": "Get everything set up and ready in under 10 minutes. All you need to do is add your information and you're ready to go.",
  "landing_home_section2_button_label": 'Start Now',
  "landing_home_what_do_you_get": 'What do you get?',
  "landing_home_card1_text": 'Present your offer online and let new customers book your services. Conduct marketing campaigns and promote your business to earn even more!',
  "landing_home_card1_label": 'Get new customers',
  "landing_home_card2_text": "Let your customers make reservations whenever they want, 24 hours a day, without the need to involve an employee to handle them. Don't allow yourself to lose a customer just because he couldn't contact you.",
  "landing_home_card2_label": "Fill your calendar",
  "landing_home_card3_text": "Check which element of your offer is the most profitable, find out which customers allow you to earn the most, and use the collected information to keep them for longer. Manage your employees' schedule and have control over their work.",
  "landing_home_card3_label": "Controll your business",
  "landing_footer_careers": "Careers",
  "landing_footer_investors": "Investors",
  "landing_footer_terms_of_service": "Terms of Service",
  "landing_footer_contact_us": "Contact Us",
  "landing_footer_contact": "Contact",
  "landing_footer_support": "Support",
  "validation.required": "Pole jest wymagane",
  "validation.required.password": "Pole jest wymagane",
  "validation.required_min_partners": "Wymagana ilość personelu: {count}",
  "validation.unique.email": "Adres email już istnieje",
  'permission.facility_list': 'Facility list',
  'permission.facility_create': 'Tworzenie obiektów',
  'permission.facility_update': 'Edycja obiektu',
  'permission.partner_list': 'Widok listy personelu',
  'permission.partner_create': 'Tworzenie konta personelu',
  'permission.partner_update': 'Edycja konta personelu',
  'permission.reservation_list': 'Widok listy rezerwacji',
  'permission.reservation_create': 'Tworzenie rezerwacji',
  'permission.reservation_update': 'Edycja rezerwacji',
  'permission.role_update': 'Zarzadzanie rolami',
  'permission.resource_list': 'Widok listy zasobów',
  'permission.resource_create': 'Tworzenie zasobu',
  'permission.resource_update': 'Edycja zasobu',
  'permission.service_list': 'Widok listy usług',
  'permission.service_create': 'Tworzenie usługi',
  'permission.service_update': 'Edycja usłgui',
  'permission.client_list': 'Widok listy klientów',
  'permission.client_create': 'Tworzenie konta klienta',
  'permission.client_update': 'Edycja konta klienta',
  'permission.business_update': 'Ustawienia biznesu',
  'sidebar.dashboard' : 'Dashboard',
  'sidebar.reservations' : 'Reservations',
  'sidebar.sales' : 'Sale',
  'sidebar.facilities' : 'Facilities',
  'sidebar.offer' : 'Offer',
  'sidebar.services' : 'Services',
  'sidebar.personnel' : 'Personnel',
  'sidebar.permissions' : 'Roles and permissions',
  'sidebar.resources' : 'Resources',
  'sidebar.resource_categories' : 'Resource categories',
  'sidebar.business' : 'Business',
  'sidebar.business_data' : 'Basic data',
  'sidebar.clients' : 'Clients',
  'sidebar.myaccount': 'My account',
  'sidebar.data': 'Data',
  'sidebar.preferences': 'Preferences',
  'sidebar.notifications': 'Notifications',
  'sidebar.logout': 'Logout',
  'sidebar.business_reservations': 'Reservation settings'
}