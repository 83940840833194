import React from "react";
import {injectIntl} from "react-intl";
import yes from '../assets/img/yes.svg'
import no from '../assets/img/no.svg'

import info from '../assets/img/info.svg'
const Plans = () => (
    <div className='plans' id='plans'>
        <div className='title'><span>Wybierz</span><br/>odpowiedni plan</div>
        <div className='container'>
            <table className='plantable'>
                <thead>
                <tr>
                    <th></th>
                    <th>Wczesny dostęp <img src={info}/></th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>Liczba obiektów</td>
                    <td className='nolimit'>bez ograniczeń</td>
                </tr>
                <tr>
                    <td>Liczba pracowników</td>
                    <td className='nolimit'>bez ograniczeń</td>
                </tr>
                <tr>
                    <td>Liczba usług</td>
                    <td className='nolimit'>bez ograniczeń</td>
                </tr>
                <tr>
                    <td>Liczba rezerwacji</td>
                    <td className='nolimit'>bez ograniczeń</td>
                </tr>
                <tr>
                    <td>Liczba klientów</td>
                    <td className='nolimit'>bez ograniczeń</td>
                </tr>
                <tr>
                    <td>Dostęp do kalendarza rezerwacji</td>
                    <td><img src={yes}/></td>
                </tr>
                <tr>
                    <td>Przyjmowanie rezerwacji usług online</td>
                    <td><img src={yes}/></td>
                </tr>
                <tr>
                    <td>Powiadomienia email dla klientów i pracowników</td>
                    <td><img src={yes}/></td>
                </tr>
                <tr>
                    <td>Możliwość rezerwacji usług online przez klientów</td>
                    <td><img src={yes}/></td>
                </tr>
                <tr>
                    <td>Niższy abonament po okresie testowym</td>
                    <td><img src={yes}/></td>
                </tr>
                <tr>
                    <td>Darmowe wsparcie i szkolenie przy wdrożeniu</td>
                    <td><img src={yes}/></td>
                </tr>
                <tr>
                    <td>Bezpłatna pomoc dedykowanego konsultanta</td>
                    <td><img src={yes}/></td>
                </tr>
                <tr>
                    <td>Cena</td>
                    <td>0 zł</td>
                </tr>
                </tbody>
            </table>
            <table className='btntable'>
                <tr>
                    <td></td>
                    <td>
                        <button onClick={() => window.location.href=process.env.REACT_APP_DASHBOARD_URL + '/signup'}>Rozpocznij wczesny dostęp</button>
                    </td>
                </tr>
            </table>
        </div>
        <div className='info'>
            <div className='text' style={{textAlign: 'center'}}>
                Naszą misją jest dostarczenie jak najlepszej jakości oprogramowania dla obiektów sportowych, dlatego już dziś zapraszamy Cię do skorzystania z zupełnie darmowego <b>wczesnego dostępu</b>.
                Będziesz mógł dzięki temu korzystać z <u>ze wszystkich funkcji oprogramowania do czerwca 2023 bez żadnych zobowiązań.</u>
            </div>
            <button>Rozpocznij okres próbny</button>
        </div>
    </div>
);

export default injectIntl(Plans);
