import React from 'react';
import {Link} from 'react-router-dom';
import {injectIntl} from "react-intl";
import {
    FaFacebook,
    FaInstagram,
    FaYoutube,
    FaTwitter,
    FaLinkedin
} from 'react-icons/fa';
import {trans} from "../utils/translationUtil";
import fb from '../assets/img/fb.svg'
import ig from '../assets/img/ig.svg'
import linkedin from '../assets/img/linkedin.svg'
import logo from '../assets/img/logo_white.svg'

function Footer({intl}) {
    return (
        <footer>
            <div className='container'>
                <div className='column'>
                    <div>
                        <img src={logo}/>
                    </div>
                    <div>Bookendy © 2022</div>
                    <div>Wszystkie prawa zastrzeżone</div>
                    <div>kontakt@bookendy.com</div>
                </div>
                <div className='column'>
                    <div className='header'>Popularne funkcje</div>
                    <div>Kalendarz rezerwacji</div>
                    <div>Obiekty rezerwacji</div>
                    <div>Zarządzanie usługami i cennikiem</div>
                    <div>Zarządzanie pracownikami</div>
                </div>
                <div className='column'>
                    <div className='header'>Firma</div>
                    <div>O nas</div>
                    <div>Polityka prywatności</div>
                    <div>Regulamin</div>
                    <div>Kontakt</div>
                </div>
                <div className='column icons'>
                    <div className='icon'>
                        <a href='https://www.facebook.com/bookendy' target='_blank'>
                            <img src={fb}/>
                        </a>
                    </div>
                    <div className='icon'>
                        <a href='https://www.facebook.com/bookendy' target='_blank'>
                            <img src={ig}/>
                        </a>
                    </div>
                    <div className='icon'>
                        <a href='https://www.linkedin.com/company/bookendy' target='_blank'>
                            <img src={linkedin}/>
                        </a>
                    </div>
                </div>
            </div>
        </footer>
    );
}


export default injectIntl(Footer);