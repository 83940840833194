export default {
    "signin": 'Zaloguj się',
    "start_for_free": "Zacznij za darmo",
    "features": "Funkcjonalności",
    "pricing": "Cennik",
    "landing_home_section1_top_line": 'Zwiększ z nami swoją liczbę rezerwacji',
    "landing_home_section1_head_line": 'Oprogramowanie do rezerwacji usług dla branży sportowej, rozrywkowej i fitness',
    "landing_home_section1_description": 'Pozwól nowym klientow dowiedzieć się o Twoich usługach i zacznij ich sprzedaż online.',
    "landing_home_section1_button_label": 'Zacznij za darmo',
    "landing_home_section2_top_line": 'Prosta konfiguracja',
    "landing_home_section2_head_line": 'Bardzo szybki i prosty proces konfiguracji systemu',
    "landing_home_section2_description": "Skonfiguruj wszystko w mniej niż 10 minut. Wszystko, co musisz zrobić, to dodać informacje o swoim biznesie i gotowe.",
    "landing_home_section2_button_label": 'Rozpocznij teraz',
    "landing_home_what_do_you_get": 'Co otrzymujesz?',
    "landing_home_card1_text": 'Zaprezentuj swoją ofertę online i pozwól nowym klientom rezerwować Twoje usługi. Prowadź kampanie marketingowe i promuj swój biznes, aby zarabiać jeszcze więcej!',
    "landing_home_card1_label": 'Pozyskaj nowych klientów',
    "landing_home_card2_text": "Pozwól swoim klientom dokonywać rezerwacji, kiedy tylko chcą, 24 godziny na dobę, bez konieczności angażowania pracownika do ich obsługi. Nie pozwól sobie na utratę klienta tylko dlatego, że nie mógł się z tobą skontaktować.",
    "landing_home_card2_label": "Zapełnij swój kalendarz",
    "landing_home_card3_text": "Sprawdź, który element Twojej oferty jest najbardziej opłacalny, dowiedz się, którzy klienci pozwalają Ci najwięcej zarabiać i wykorzystaj zebrane informacje, aby zatrzymać ich na dłużej. Zarządzaj harmonogramem pracowników i miej kontrolę nad wykonywaną przez nich pracą.",
    "landing_home_card3_label": "Kontroluj swój biznes",
    "landing_footer_careers": "Kariera",
    "landing_footer_investors": "Inwestorzy",
    "landing_footer_terms_of_service": "Warunki świadczenia usług",
    "landing_footer_contact_us": "Skontaktuj się z nami",
    "landing_footer_contact": "Kontakt",
    "landing_footer_support": "Pomoc",
    "validation.required": "Pole jest wymagane",
    "validation.invalid_password": 'Zły email lub hasło',
    "validation.required_min_partners": "Wymagana ilość personelu: {count}",
    "validation.unique": "Wartość już istnieje",
    'validation.same': 'Hasła nie są identyczne',
    'validation.min.string': 'Wymagane conajmniej 8 znaków',
    'validation.account_blocked': 'Konto jest zablokowane',
    "validation.unique.email": "Adres email już istnieje",
    "validation.confirmed": "Hasła nie zgadzaja się",
    'validation.match.password': 'Hasło nie zgadza się z aktualnym',
    'permission.facility_list': 'Widok listy obiektów',
    'permission.facility_create': 'Tworzenie obiektów',
    'permission.facility_update': 'Edycja obiektu',
    'permission.partner_list': 'Widok listy personelu',
    'permission.partner_create': 'Tworzenie konta personelu',
    'permission.partner_update': 'Edycja konta personelu',
    'permission.reservation_list': 'Widok listy rezerwacji',
    'permission.reservation_create': 'Tworzenie rezerwacji',
    'permission.reservation_update': 'Edycja rezerwacji',
    'permission.role_update': 'Zarzadzanie rolami',
    'permission.resource_list': 'Widok listy zasobów',
    'permission.resource_create': 'Tworzenie zasobu',
    'permission.resource_update': 'Edycja zasobu',
    'permission.service_list': 'Widok listy usług',
    'permission.service_create': 'Tworzenie usługi',
    'permission.service_update': 'Edycja usłgui',
    'permission.client_list': 'Widok listy klientów',
    'permission.client_create': 'Tworzenie konta klienta',
    'permission.client_update': 'Edycja konta klienta',
    'permission.business_update': 'Ustawienia biznesu',
    'sidebar.dashboard' : 'Dashboard',
    'sidebar.reservations' : 'Rezerwacje',
    'sidebar.sales' : 'Sprzedaż',
    'sidebar.facilities' : 'Obiekty',
    'sidebar.offer' : 'Oferta',
    'sidebar.services' : 'Usługi',
    'sidebar.personnel' : 'Personel',
    'sidebar.permissions' : 'Role i uprawnienia',
    'sidebar.resources' : 'Zasoby',
    'sidebar.resource_categories' : 'Kategorie zasobów',
    'sidebar.business' : 'Biznes',
    'sidebar.business_data' : 'Dane podstawowe',
    'sidebar.clients' : 'Klienci',
    'sidebar.myaccount': 'Moje konto',
    'sidebar.data': 'Dane',
    'sidebar.preferences': 'Preferencje',
    'sidebar.notifications': 'Powiadomienia',
    'sidebar.logout': 'Wyloguj',
    'sidebar.business_reservations': 'Ustawienia rezerwacji',
    'sidebar.reservationhistory': 'Historia rezerwacji',
    'reservationstatus.confirmed': 'Potwierdzona',
    'reservationstatus.cancelled': 'Anulowana',
    'reservationstatus.settled': 'Rozliczona',
    'reservationstatus.finished': 'Zakończona',
    'reservationstatus.rejected': 'Odrzucona',
    'reservationstatus.waiting_for_client_approval': 'Oczekuje na potwierdzenie klienta',
    'reservationstatus.waiting_for_partner_approval': 'Oczekuje na potwierdzenie biznesu',
    'facility': 'Obiekt',
    'reservation_status': 'Status rezerwacji',
    'service': 'Usługa'
}