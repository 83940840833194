import React from 'react';
import {injectIntl} from "react-intl";
import logo from '../assets/img/logo_white.svg'

function Navbar() {

    let goTo = id => {
        let element = document.getElementById(id)
        window.scrollTo(element.getBoundingClientRect().left, element.getBoundingClientRect().top + window.scrollY - 150);
    }

    return <header>
        <img src={logo} alt=''/>
        <div className='panel'>
            <div className='navigation'>
                <div onClick={() => goTo('features')}>
                    <span>Funkcje</span>
                </div>
                <div onClick={() => goTo('plans')}>
                    <span>Cennik</span>
                </div>
                <div onClick={() => goTo('write-us')}>
                    <span>Kontakt</span>
                </div>
            </div>
            <div className='buttons'>
                <button className='start' onClick={() => window.location.href=process.env.REACT_APP_DASHBOARD_URL + '/signup'}>Zacznij za darmo</button>
                <button className='login' onClick={() => window.location.href=process.env.REACT_APP_DASHBOARD_URL + '/signin'}>Zaloguj się</button>
            </div>
        </div>
    </header>
}

export default injectIntl(Navbar);