import React from "react";
import {injectIntl} from "react-intl";
import Landing from "../layouts/Landing";
import Heading from '../components/Heading'
import InfoSection from '../components/InfoSection'
import Features from '../components/Features'
import Plans from '../components/Plans'
import BgInfo from '../components/BgInfo'
import WriteUs from '../components/WriteUs'
import Faq from '../components/Faq'
import info1 from '../assets/img/info1.svg'
import info2 from '../assets/img/info2.png'
import info3 from '../assets/img/info3.svg'

const Home = () => (
    <Landing>
        <Heading/>
        <InfoSection title='Daj się znaleźć klientom'
                     info='Większość klientów rozpoczyna poszukiwania obiektów sportowych i rozrywkowych w sieci. Jeśli Cie tam nie znajdą, z pewnością zarezerwują inne, bardziej dostępne obiekty. Nie pozwól sobie na utratę potencjalnych klientów.'
                     image={info1}/>
        <InfoSection title='Ułatw klientom rezerwację usług'
                     reverse={true}
                     info='Czy klienci mają możliwość rezerwacji Twoich usług wygodny sposób, o dowolnej porze? Czy chciałbyś zredukować czas przeznaczany na odbieranie telefonów? Czy zdarza się, że klienci nie zjawiają się bez odwołania rezerwacji? Bookendy oferuje proste umawianie wizyt online,  przypomnienia o rezerwacjach i możliwość wygodnej zmiany terminu rezerwacji. Wszystko to jest dostępne w każdej chwili, z dowolnego urządzenia.'
                     image={info2}/>
        <InfoSection title='Miej kontrolę nad swoim biznesem'
                     info='Sprawdź, który element Twojej oferty jest najbardziej opłacalny, dowiedz się, którzy klienci pozwalają Ci najwięcej zarabiać i wykorzystaj zebrane informacje, aby zatrzymać ich na dłużej. Zarządzaj harmonogramem pracowników i miej kontrolę nad wykonywaną przez nich pracą.'
                     image={info3}/>
        <Features/>
        <BgInfo/>
        <Plans/>
        <Faq/>
        <WriteUs/>
    </Landing>
);

export default injectIntl(Home);
