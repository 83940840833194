import React from "react";

import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import {injectIntl} from "react-intl";

let Landing = ({intl, children}) => <>
    <Navbar intl={intl}/>
    {children}
    <Footer/>
</>

export default injectIntl(Landing);
