import React, {useState} from "react";
import {injectIntl} from "react-intl";
import FaqItem from './FaqItem'

const data = [
    {
        question: 'Ile kosztuje korzystanie z Bookendy?',
        answer: 'W okresie wczesnego dostępu nic nie płacisz - masz czas do czerwca 2023, aby przekonać się, czy Bookendy jest dla Ciebie. Po tym czasie podejmiesz decyzję czy chcesz zostać z nami dłużej.'
    },
    {
        question: 'Czym jest wczesny dostęp?',
        answer: 'Okres wczesnego dostępu posłuży nam do jak najlepszego dostosowania naszego oprogramowania do Twoich potrzeb. Podczas jego trwania chcemy zebrać jak najwięcej informacji od użytkowników i stworzyć idealny produkt.'
    },
    {
        question: 'Czy po okresie wczesnego dostępu utracę swoje dane takie jak informacje o rezerwacjach i klientach?',
        answer: 'Nie - wszystkie Twoje dane zostaną zachowane, jeśli zdecydujesz się pozostać z nami po okresie wczesnego dostępu.'
    }
]

const Faq = () => <div className='faq'>
    <div className='title'>
        Najczęściej zadawane <span>pytania</span>
    </div>
    <div className='container'>
        {
            data.map(faq => <FaqItem question={faq.question} answer={faq.answer}/>)
        }
    </div>
</div>

export default injectIntl(Faq);
