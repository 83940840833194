import React from "react";
import {injectIntl} from "react-intl";
import heading from '../assets/img/heading.svg'
import {toast} from "react-toastify"

const WriteUs = () => {

    let [name, setName] = React.useState()

    let [email, setEmail] = React.useState()

    let [submitting, setSubmitting] = React.useState(false)

    let [content, setContent] = React.useState()

    let submitForm = (event) => {
        setSubmitting(true)
        event.preventDefault()
        fetch(process.env.REACT_APP_API_URL + '/contact-request', {
            method: 'POST',
            body: JSON.stringify({
                email,
                name,
                content
            }),
            headers: {
                'Content-Type': 'application/json'
            },
        }).then(response => {
            if (response.status !== 201) {
                toast.error('Wystąpił błąd!', {hideProgressBar: true});
                return;
            }
            toast.success('Wysłano!', {hideProgressBar: true});
            setEmail('')
            setName('')
            setContent('')
            setSubmitting(false)

        }).catch(e => {
            setSubmitting(false)
            toast.error('Wystąpił błąd!', {hideProgressBar: true});
        })
    }

    return <div className='write-us' id='write-us'>
        <div className='title'>Potrzebujesz dodatkowych informacji?<br/>Napisz do nas!</div>
        <form className='container' onSubmit={submitForm}>
            <div className='row'>
                <input placeholder='Imię i nazwisko' required value={name} onChange={e => setName(e.target.value)}/>
                <input type="email" placeholder='Adres email' required value={email}
                       onChange={e => setEmail(e.target.value)}/>
            </div>
            <div className='row'>
                <textarea rows="8" placeholder='Treść wiadomości' required value={content}
                          onChange={e => setContent(e.target.value)}></textarea>
            </div>
            <div className='row'>
                <button type='submit' disabled={submitting}>Wyślij wiadomość</button>
            </div>
        </form>
    </div>
}

export default injectIntl(WriteUs);
