import React from "react";
import {injectIntl} from "react-intl";
import heading from '../assets/img/heading.svg'

const InfoSection = ({title, info, image, reverse = false}) => (
    <div className='info-section'>
        <div className={'container ' + (reverse ? 'reverse' : '')}>
            <div className='text'>
                <div className='title'>{title}</div>
                <div className='info'>{info}</div>
            </div>
            <div className='image'>
                <img src={image}/>
            </div>
        </div>
    </div>
);

export default injectIntl(InfoSection);
