import {BrowserRouter} from "react-router-dom";
import {IntlProvider} from "react-intl";
import langData from './lang/langData';
import Routes from "./routes/Routes";
import 'react-toastify/dist/ReactToastify.css';
import {ToastContainer} from "react-toastify";
import './assets/scss/app.scss';

function App({current_language}) {

    let currentLanguage = current_language?.code ?? 'pl';

    return (
        <BrowserRouter>
            <IntlProvider
                key={currentLanguage}
                locale={currentLanguage}
                messages={langData[currentLanguage]}>
                <Routes/>
            </IntlProvider>
            <ToastContainer/>
        </BrowserRouter>
    );
}

export default App;
