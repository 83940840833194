import React, {useState} from "react";
import {injectIntl} from "react-intl";
import Collapse from '@mui/material/Collapse';
import feature1 from '../assets/img/features/feature1.svg'
import feature2 from '../assets/img/features/feature2.svg'
import feature3 from '../assets/img/features/feature3.svg'
import icon1 from '../assets/img/features/icon1.svg'
import icon2 from '../assets/img/features/icon2.svg'
import icon3 from '../assets/img/features/icon3.svg'
import feature0 from '../assets/img/features/default.svg'

const steps = {
    0: {
        image: feature0
    },
    1: {
        icon: icon1,
        title: 'Wypełnij kalendarz',
        image: feature1,
        description: 'Rezerwacje online 24/7, automatyczne przypomnienia o wizycie, prosta w obsłudze aplikacja dla klientów, integracja z Facebookiem, Instagramem i Google.'
    },
    2: {
        icon: icon2,
        title: 'Zarządzaj zespołem',
        image: feature2,
        description: 'Zarządzanie zespołem i ustalanie grafików pracy. Różne poziomy dostępu, szybsze i łatwiejsze ustalanie harmonogramów prac.'
    },
    3: {
        icon: icon3,
        title: 'Zarządzaj swoim biznesem',
        image: feature3,
        description: 'Finanse, pracownicy, formalności. Kontroluj je za pomocą naszych narzędzi.'
    },
}

const Features = () => {
    let [currentStep, setCurrentStep] = useState(0)

    return <div className='features' id='features'>
        <div className='title'>
            <span>Zobacz jakie funkcjonalności<br/> mamy dla <u>Ciebie</u></span>
        </div>
        <div className='container'>
            <div className='content d-flex-row'>
                <div className='d-flex-column items'>
                    {
                        Object.keys(steps)
                            .filter(step => step > 0)
                            .map(step => {
                                return <div className='item' onClick={() => {
                                    setCurrentStep(parseInt(step))
                                }}>
                                    <div className='header'>
                                        <img src={steps[step].icon}/>
                                        <span>{steps[step].title}</span>
                                    </div>
                                    <Collapse in={currentStep === parseInt(step)}>
                                        <div className='description'>
                                            {steps[step].description}
                                        </div>
                                    </Collapse>

                                </div>
                            })
                    }
                </div>
                <div className='image'>
                    <img src={steps[currentStep].image ?? feature0} alt=''/>
                </div>
            </div>
        </div>
    </div>
}

export default injectIntl(Features);
