import React from "react";
import {injectIntl} from "react-intl";
import heading from '../assets/img/heading.svg'

const BgInfo = () => (
    <div className='bginfo'>
        <div className='container'>
            <div>
                Wprowadź swój biznes<br/>
                na wyższy poziom z <u>Bookendy</u><br/>
                już teraz!
            </div>
            <div className='buttons'>
                <button className='start' onClick={() => window.location.href=process.env.REACT_APP_DASHBOARD_URL + '/signup'}>Zacznij za darmo</button>
                <button className='presentation' onClick={() => window.location.href=process.env.REACT_APP_PRESENTATION_URL}>Zarezerwuj prezentacje</button>
            </div>
        </div>
    </div>
);

export default injectIntl(BgInfo);
