import React from "react";
import {injectIntl} from "react-intl";
import heading from '../assets/img/heading.svg'
import Typewriter from 'typewriter-effect/dist/core';

const Heading = () => {
    React.useEffect(() => {
        new Typewriter('#typewriter', {
            strings: ['kręgielnią', 'boiskiem', 'halą sportową', 'kortem tenisowym'],
            autoStart: true,
            loop: true,
        });
    }, [])
    return <div className='heading'>
        <div className='info'>
            <div>
                Zarządzaj <span id='typewriter'></span><br/> i pozyskuj z nami nowych klientów
            </div>
            <div>
                <button className='start' onClick={() => window.location.href=process.env.REACT_APP_DASHBOARD_URL + '/signup'}>Zacznij za darmo</button>
                <button className='presentation' onClick={() => window.location.href=process.env.REACT_APP_PRESENTATION_URL}>Umów prezentację</button>
            </div>
        </div>
        <div>
            <img src={heading} alt=''/>
        </div>
    </div>
}

export default injectIntl(Heading);
