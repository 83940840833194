import React, {useState} from "react";
import {injectIntl} from "react-intl";
import Collapse from '@mui/material/Collapse';
import arrow from '../assets/img/arrow.svg'

const FaqItem = ({question, answer}) => {

    let [isOpen, setIsOpen] = useState(false)

    return <div className={'item ' + (isOpen ? 'opened' : '')}>
        <div>
            <div className='question' onClick={() => setIsOpen(!isOpen)}>
                {question}
            </div>
            <Collapse in={isOpen}>
                <div className='answer'>
                    {answer}
                </div>
            </Collapse>
        </div>
        <div className='icon'>
            <img src={arrow} onClick={() => setIsOpen(!isOpen)}/>
        </div>
    </div>
}

export default injectIntl(FaqItem);
